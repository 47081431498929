.App {
  padding: 20px;
  width: 100%;
}

@media only screen and (max-width: 700px) {
  img {
    display: none;
  }
}


.Quote {
  font-family: Serif;
  text-align: center;
  font-style: italic;
}

.Quote .p-panel-content {
  background: #fdfdfd;
  border-top: 1px solid #dee2e6;
}

.top-border {
  border-top: 1px solid #dee2e6;
}


.Content .p-panel-content {
  text-align: left;
  line-height: 2;
  color: black;
}

.Content h3 {
  font-weight: 300;
}

.Content img {
  float: right;
  width: 50%;
  padding: 30px;
}

.Content .Source {
  font-size: 0.67em;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Share button {
  padding: 5px !important;
}

.p-panel {
  padding-bottom: 20px;
}

.p-panel-content {
  text-align: center;
}

.p-col-6 {
  padding: 30px;
}

.Menu a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}